<template>
  <div>
    <div
      v-if="!error"
      class="container text-left"
      v-html="geneFamilyContent"
    ></div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
export default {
  name: "GeneFamily",
  components: {
    PageNotFound,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "genefamily/",
      geneFamilyContent: "",
      loading: false,
      error: false,
    };
  },
  mounted() {
    this.fetchGeneFamilyContent();
  },
  methods: {
    async fetchGeneFamilyContent() {
      let geneFamilyId = this.$route.params.key;
      try {
        this.loading = true;
        let response = await PostsService.getGeneFamilyDetail({
          key: geneFamilyId,
        });
        this.geneFamilyContent = response.data;
        this.geneFamilyContent = this.geneFamilyContent.replace('<A href="index.jsp"><IMG SRC="/images/arrow.small.up.gif"border=0>Return to Gene Family Index</A>', '');
        document.title = "Gene Family - " + geneFamilyId + " - TAIR";
        this.loading = false;
      } catch (error) {
        console.error("Error fetching gene family content:", error);
        this.loading = false;
        this.error = true;
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100vw; /* 100% of the viewport width */
  padding: 20px; /* Optional: adjust as needed */
  box-sizing: border-box; /* Includes padding in height and width */
}
</style>
